<template>
  <div class="home">
    <div ref="scrolList" class="home-box">
      <div class="home-info">
        <div class="home-info-name">
          <div class="home-info-name-text">{{ allData.bizName }}</div>
          <div class="home-info-name-status">{{ changeStatus(allData.signStatus) }}</div>
        </div>
        <div class="home-info-type" v-show="allData.contractType">
          合同类型：<span>{{allData.contractTypeName}}</span>
        </div>
        <div class="home-info-time">
          签署有效期：<span>{{  changeTime(allData.signDeadline) }}</span>
        </div>
        <div class="home-info-look" @click="lookD(true)">
          合同正文：<span class="home-info-look-span">查看合同</span>
        </div>
      </div>
      <div class="home-detail">
        <div class="home-detail-title">
          <div class="home-detail-title-text">签署详情</div>
          <div style="font-size:12px;color:#8B96A6;">本合同由{{allData.initiatorName}}于{{changeTime(allData.sendTime, 'yyyy-MM-dd hh:mm:ss')}}发起签署流程</div>
          <div class="home-detail-title-de">{{ detail.title }}</div>
        </div>
        <div class="home-detail-content">
          <van-steps direction="vertical" :active="1">
            <van-step v-for="(item, index) in process" :key="item.id">
              <template v-slot:active-icon>
                <div
                  :class="{'active-icon' : true, 'active-icon-imgok': item.actionStatus === 2,  'active-icon-imgrefuse': item.actionStatus === 3, 'active-icon-imgnotSigned': item.actionStatus === 1 && (allData.signStatus === 4 || allData.signStatus === 5 || allData.signStatus === 6), 'active-icon-imgnow': item.actionStatus === 1 && allData.signStatus !== 4 &&  allData.signStatus !== 5 && allData.signStatus !== 6 }"
                ></div>
              </template>
              <div class="home-detail-content-box">
                <div class="home-detail-content-status">
                  <div :class="{ 'home-detail-content-status-color': true, 'home-detail-content-status-color1': item.actionStatus == 2 ? true : false, 'home-detail-content-status-color2': item.actionStatus == 3? true : false, 'home-detail-content-status-color3': item.actionStatus == 1 && (allData.signStatus === 4 || allData.signStatus  === 5 || allData.signStatus === 6) ? true : false  }" >
                    <div class="home-triangle" :style="{ 'border-top': `8px solid ${ (allData.signStatus !== 4 && allData.signStatus !== 5 && allData.signStatus !== 6 && item.actionStatus === 1 ) ? '#0067E1' : '#c0c0c0' || ( item.actionStatus === 2 ? '#00D1B3' : '#FF6565')}` }" />
                      {{ operationStatus(item.actionStatus, index, process) }}
                    </div>
                  <div
                    v-if="item.actionStatus !== 1&&item.actionTime"
                    class="home-detail-content-status-time"
                  >
                    签署时间：{{ changeTime(item.actionTime, 'yyyy-MM-dd hh:mm:ss') }}
                  </div>
                </div>
                <div class="home-detail-content-company">
                  <div class="home-detail-content-company-span">
                    {{item.signPartyName}}
                  </div>
                  <div class="home-detail-content-company-box">
                    <div class="home-detail-content-company-box-name" v-if="item.companyName">
                      {{ item.companyName }}
                    </div>
                    <div
                      class="home-detail-content-company-box-handled"
                    >
                      <div class="home-detail-content-company-box-handled-de">
                        <span
                          class="
                            home-detail-content-company-box-handled-de-title
                          "
                          >{{item.companyId ? '经办人：': '签约人：'}}</span
                        >
                        <span
                          class="
                            home-detail-content-company-box-handled-de-content
                          "
                          >{{ item.signerName }}</span
                        >
                      </div>
                      <div class="home-detail-content-company-box-handled-ph">
                        <span
                          class="
                            home-detail-content-company-box-handled-ph-title
                          "
                          >联系电话：</span
                        >
                        <span
                          class="
                            home-detail-content-company-box-handled-de-content
                          "
                          >{{ item.signerContact }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="home-options">
        <div class="home-options-title">操作记录</div>
        <div class="home-options-content">
          <van-steps direction="vertical" :active="0">
            <van-step v-for="(item, index) in operationHistory" :key="index">
              <template v-slot:active-icon>
                <div :class="{ 'options-active-icon': true, 'options-active-icon-faq': item.operateType === 1, 'options-active-icon-qianshu': item.operateType === 2, 'options-active-icon-ju': item.operateType === 3, 'options-active-icon-c': item.operateType === 4, 'options-active-icon-look': item.operateType === 10, 'options-active-icon-down': item.operateType === 11, 'options-active-icon-dy': item.operateType === 12  }"></div>
              </template>
              <div class="home-options-content-box">
                <!-- {{ item.operatorName }}{{ changeHorStatus(item.operateType) }} -->
                {{item.operatorName == '和诚签' ?  `${item.operatorName}+${item.operatorContact}` : item.operatorName}}{{ changeHorStatus(item.operateType) }}
              </div>
              <div class="home-options-content-time">{{ changeTime(item.operateTime, 'yyyy-MM-dd hh:mm:ss') }}</div>
              <div @click="reason(item.extraInfo)" v-if="item.operateType===3" class="home-options-content-reason">查看原因</div>
            </van-step>
          </van-steps>
        </div>
      </div>
    </div>
    <div class="home-btn" :style="{ 'marginBottom': false ? '24px' : '0' }">
      <template v-if="allData.signStatus == 2">
        <div class="home-btn-back" v-if="allData.initiatorUserId === userInfo.jyqUserId" @click="withdrawF">撤回</div>
        <div class="home-btn-back" v-if="allData.initiatorUserId !== userInfo.jyqUserId&&isShowDBtn" @click="withdrawF">拒签</div>
        <div class="home-btn-sign" v-if="isShowDBtn" @click="goSign">去签署</div>
        <div class="home-btn-down" v-if="downShow" @click="download()">下载</div>
        <div class="home-btn-details" v-if="!isShowDBtn" @click="lookD(false)">查看明细</div>
      </template>
      <template v-if="allData.signStatus != 2">
        <div class="home-btn-down" v-if="downShow&&allData.signStatus != 6" @click="download()">下载</div>
        <div class="home-btn-details" @click="lookD(false)">
          查看明细
        </div>
      </template>
    </div>
    <van-popup v-model="show" position="bottom" >
      <div class="reson-box">
        <div class="reson-box-title">拒绝原因</div>
        <textarea class="reson-box-textarea" name="description" v-model="textarea" placeholder="请输入拒绝原因" style="resize:none;"></textarea>
        <div class="reson-box-btn" @click="submitReason">确定</div>
      </div>
    </van-popup>
    <div class="use-list" @click="listClick()">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import { signatureDetails, withdraw ,signature, download, getCompanyRealnameInfo } from "@/util/api";
import { changeFormData, downloadFile } from "@/util/tool";
import { mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      info: {},
      detail: {},
      contractInformation: [],

      allData: {},
      operationHistory: [],
      process: [],
      show: false,
      reasonData: '',
      textarea: '',
      partyType: '1',
      companyId: '',
      signPartyType: '1'
    };
  },
  methods: {
    ...mapMutations(['setSignatureDetails','setSignPartyType']),
    listClick() {
      this.$router.push({path: '/usePage',query:{}})
    },
    download() {
      let signatureId = this.$route.query.signatureId ? this.$route.query.signatureId : this.linkCodeInfo.signatureId
      download({signatureId}).then(res => {
        const { data, fileName, type, code, msg } = res
        if (code == 0) {
          downloadFile(data, type, window.decodeURI(fileName).split("''")[1])
        } else {
          this.$toast.fail(msg)
        }
      })
    },
    operationStatus (status, index, process) {
      let value = ''
       switch(status) {
          case 1:
            if(this.allData.signInOrderFlag && (this.allData.signStatus !== 5 && this.allData.signStatus !== 6 && this.allData.signStatus !== 4)){
              if(index > 0 && process[index - 1].actionStatus == 1){
                value = '待操作'
              }else{
                value = '签约中'
              }
            }else{
              if(this.allData.signStatus == 5 || this.allData.signStatus == 6 || this.allData.signStatus == 4){
                value = '未签署'
              }else{
                value = '签约中'
              }
            }
            // if (this.allData.signInOrderFlag) {
            //   if (index == 0) {
            //     if(this.allData.signStatus == 5){
            //       value = '未签署'
            //     }else{
            //       value = '签约中'
            //     }
            //   } else {
            //     if (process[index - 1].actionStatus == 1) {
            //       value = '待操作'
            //     } else {
            //       value = '未签署'
            //     }
            //   }
            // } else {
            //   value = '未签署'
            // }
            break;
          case 2:
            value = '已签署'
            break;
          case 3:
            value = '已拒签'
            break;
          default:
            value = ''
        }
        return value
    },
    isX() {
      return (
        /iphone/gi.test(window.navigator.userAgent) &&
        window.screen.height >= 812
      );
    },
    goSign() {
      console.log(this.linkCodeInfo,'this.linkCodeInfo');
      let signatureId = this.$route.query.signatureId ? this.$route.query.signatureId : this.linkCodeInfo.signatureId 
      let companyId = this.$route.query.companyId ? this.$route.query.companyId : this.companyId
      // this.$router.push({ path: '/sign',query:{signatureId:signatureId, partyType: this.partyType, companyId: companyId}})
      console.log(111)
      if(this.partyType == 1 && this.allData.tenantEnterpriseType == 1){
        getCompanyRealnameInfo(companyId).then(res =>{
          console.log(res);
          if (res.lastRealnameSuccessInfo) {
            this.$router.push({ path: '/sign',query:{signatureId:signatureId, partyType: this.partyType}})
          } else if(res.realnameStatus == 0 || res.realnameStatus == -1){
            this.$dialog.alert({
              title:'提示',
              message:'该企业未实名认证，请先完成企业实名认证',
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              showCancelButton: true
            }).then(()=>{
              this.$router.push({path:'/enterpriseCertification',query:{companyId:companyId}})
            }).catch(()=>{
              this.$toast('已取消')
            })
          }else if(res.realnameStatus == 2){
            this.$toast.fail('该企业正在审核中，请耐心等待')
            return
          }else{
            this.$router.push({ path: '/sign',query:{signatureId:signatureId, partyType: this.partyType}})
          }
        })
      }else{
        this.$router.push({ path: '/sign',query:{signatureId:signatureId, partyType: this.partyType}})
      }
      // if (!this.userInfo.realnameId) {
      //   this.$router.push({ path: '/realName' })
      //   return
      // }
    },
    getDetailData () {
      console.log(this.linkCodeInfo,'this.linkCodeInfo');
      console.log(this.$route.query,'this.$route.query');
      let signatureId = this.$route.query.signatureId ? this.$route.query.signatureId : this.linkCodeInfo.signatureId
      const params = changeFormData({ signatureId })
      signatureDetails(params).then(res => {
        console.log(res);
        this.$store.commit('setSignatureDetails',res)
        this.allData = res
        this.operationHistory = res.operationHistory || []
        this.process = res.process || []
        this.process.map(item => {
          
          if(item.signerContact.includes(this.userInfo.contact) || this.userInfo.contact.includes(item.signerContact)) {
            this.partyType = item.partyType
            this.companyId = item.companyId
            this.signPartyType = item.signPartyType
            this.$store.commit('setSignPartyType',item.signPartyType)
          }
        })
      })
    },
    changeStatus(v) {
      return v === 1 ? '草稿' : v=== 2 ? '签署中' : v === 3 ? '已完成' : v === 4 ? '已拒绝' : v === 5 ? '已撤回' : v === 6 ? '已过期' : ''
    },
    changeTime(v, format='yyyy-MM-dd') {
      return new Date(v).format(format)
    },
    withdrawF() {
      if (this.allData.initiatorUserId === this.userInfo.jyqUserId) {
        if (this.allData.signStatus === 5) {
          this.$toast.fail('合同已撤回！')
          return
        }
        this.$dialog.confirm({
          title: '撤回',
          message: '确定撤回合同吗？',
        }).then(() => {
          withdraw({ signatureId: this.$route.query.signatureId ? this.$route.query.signatureId : this.linkCodeInfo.signatureId }).then(() => {
            // this.$toast('ok')
            this.getDetailData()
          })
        })
        .catch(() => {
          // on cancel
        })
      } else {
        if (this.allData.signStatus === 4) {
          this.$toast.fail('合同已拒签！')
          return
        }
        this.show = true
      }

    },
    changeHorStatus (v) {
      let value = ''
        switch(v) {
          case 1:
            value = '发起'
            break;
          case 2:
            value = '签署'
            break;
          case 3:
            value = '拒绝'
            break;
          case 4:
            value = '撤回'
            break;
          case 10:
            value = '查看'
            break;
          case 11:
            value = '下载'
            break;
          case 12:
            value = '打印'
            break;
          default:
            value = ''
        }
      return value
      // return v === 1 ? '发起' : v === 2 ? '签署' : v === 3 ? '拒绝' : v === 10 ? '查看'  : v=== 11 ? '下载' : v=== 12 ? '打印' : ''
    },
    lookD (val) {
      // viewSignature({id: this.allData.id, originFlag: val}).then((res) => {
      //   ossUrl(res)
      // })
      this.$router.push({ path: '/details', query: { val }})
      //  ossPreviewUrl({ path: this.allData.tmpDocUrl }).then((res) => {
      //   ossUrl(res)
      // })
    },
    reason(reason) {
      // this.show = true
      // this.reasonData = reason
      this.$dialog.alert({
          title: '拒签原因',
          message: `${reason}`
        }).then(() => {
          // on close
        })
    },
    submitReason() {
      if (!this.textarea) {
        this.$toast.fail('请输入拒绝原因！')
        return
      }
      const params = {
        actionType: 2,
        processSigns: [{ details: [], processId: this.linkCodeInfo.processId }],
        rejectReason: this.textarea,
        signatureId: this.$route.query.signatureId ?  this.$route.query.signatureId : this.linkCodeInfo.signatureId
      }
      signature(params).then(res => {
        console.log(res);
        this.$toast.success('拒签成功！')
        this.getDetailData()
        this.show = false
      })
    }
  },
  computed: {
    ...mapState(['linkCodeInfo', 'userInfo','signatureDetails','resultInfo']),
    isShowDBtn () {
      const { signStatus, signInOrderFlag, process } = this.allData
      const { id, contact } = this.userInfo
      if (signStatus === 2) {
        if (process.length != 0 && process.length !== 1) {
          if (signInOrderFlag) {
            let result = []
            process.map(item => {
              if (item.actionStatus == 1) {
                result.push(item)
              }
            })
            return result.length != 0&&result[0].signerId == id
          } else {
            let result = []
            process.map(item => {
              // if (item.signerId == id&&item.actionStatus == 1) {
              console.log(item.signerContact.includes(contact));
              if ((item.signerContact.includes(contact)||contact.includes(item.signerContact))&&item.actionStatus == 1) {
                result.push(item)
              }
            })
            return result.length != 0
            }
        } else if ( process.length === 1){
          return true
        }
      }
      return false
    },
    downShow () {
      const { process } = this.allData
        if (process != undefined&&process.length != 0) {
          let result = []
          process.map(item => {
            if (item.actionStatus != 2) {
              result.push(item)
            }
          })
          return result.length == 0
        }
      return false
    }
  },
  created() {
    this.getDetailData()
  },
};
</script>

<style lang='less' scoped>
.home {
  width: 100%;
  height: 100%;
  background: #eef3fc;
  display: flex;
  flex-direction: column;
  .use-list {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    top: 400px;
    right: 10px;
    width: 48px;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 1px rgba(70,70,70,0.08);
    border-radius: 25px;
    opacity: 1;
    span {
      display: inline-flex;
      width: 20px;
      height: 4px;
      background: #8B96A6;
      border-radius: 1px;
    }
    span:nth-child(2) {
      margin: 3px 0px;
    }
  }
  &-info {
    border-radius: 4px;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 353px;
    // height: 199px;
    opacity: 1;
    border-radius: 4px;
    margin: 20px auto 0;
    box-sizing: border-box;
    &-name {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-text {
        width: 260px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #30586c;
      }
      &-status {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #0067e1;
        opacity: 1;
      }
    }
    &-type {
      font-size: 14px;
      color: #8b96a6;
      margin-top: 26px;
      span {
        color: #464646;
      }
    }
    &-time {
      font-size: 14px;
      color: #8b96a6;
      margin-top: 20px;
      span {
        color: #464646;
      }
    }
    &-look {
      font-size: 14px;
      color: #8b96a6;
      margin-top: 20px;
      &-span {
        color: #0067e1;
      }
    }
  }
  &-detail {
    border-radius: 4px;
    width: 353px;
    padding: 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-top: 10px;
    box-sizing: border-box;
    margin: 16px auto 0;
    &-title {
      &-text {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #30586c;
        opacity: 1;
      }
      &-de {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8b96a6;
        opacity: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-content {
      margin-top: 10px;
      &-box {
        color: #555555;
        font-size: 12px;
      }
      &-company {
        font-size: 14px;
        color: #555555;
        margin-top: 14px;
        display: flex;
        flex-direction: row;
        &-span {
          width: 59px;
          height: 100%;
          line-height: 24px;
          text-align: center;
          background: #f6f7f8;
          opacity: 1;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #30586c;
          opacity: 1;
        }
        &-box {
          margin-left: 6px;
          &-name {
            font-size: 16px;
            // height: 24px;
            line-height: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #464646;
            opacity: 1;
            margin-bottom: 13px;
          }
          &-handled {
            display: flex;
            flex-direction: column;
            &-de,
            &-ph {
              margin-top: 13px;
              &-title {
                width: 48px;
                height: 17px;
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 17px;
                color: #8b96a6;
                opacity: 1;
              }
              &-content {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 17px;
                color: #464646;
                opacity: 1;
              }
            }
            &-de {
              margin-top: 0px;
            }
          }
        }
      }
      &-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #00d1b3;
        opacity: 1;
        white-space: nowrap;

        &-color {
          color: #0000ff;
          display: flex;
        }
        &-color1 {
          color: #00d1b3;
        }
        &-color2 {
          color: #FF6565;
        }
        &-color3 {
          color: #c0c0c0
        }
        &-time {
          margin-left: 6px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #c0c0c0;
          opacity: 1;
          white-space: nowrap;

        }
      }
    }
  }
  &-options {
    width: 353px;
    padding: 16px;
    background: #fff;
    margin: 20px auto 24px;
    box-sizing: border-box;
    border-radius: 4px;
    &-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #30586c;
      opacity: 1;
    }
    &-content {
      margin-top: 10px;
      &-box {
        color: #555555;
      }
      &-time {
        margin-top: 4px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #C8D0DB;
      }
      &-reason {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #0067E1;
        opacity: 1;
        margin-top: 6px;
      }
    }
  }
  &-box {
    flex: 1;
    overflow: auto;
    margin-bottom: 60px;
  }
  &-btn {
    position: fixed;
    // bottom: 24px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    background: #ffffff;
    box-sizing: border-box;
    z-index: 11;
    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 164px;
      height: 44px;
      background: #f6f7f8;
      text-align: center;
      font-size: 14px;
      line-height: 44px;
      border-radius: 30px;
    }
    &-sign {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 164px;
      height: 44px;
      text-align: center;
      background: #0067e1;
      line-height: 44px;
      color: #fff;
      border-radius: 30px;
    }
    &-details {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 44px;
      border-radius: 30px;
      background: #0067E1;
      margin-left: 20px;

      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 44px;
      color: #FFFFFF;
      opacity: 1;
      text-align:center;
    }
    &-down {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 164px;
      height: 44px;
      text-align: center;
      // border: 1px solid #0067e1;
      background: rgba(0, 103, 225, 0.1);
      line-height: 44px;
      color: #0067e1;
      border-radius: 30px;
    }
  }
  .active-icon {
    width: 24px;
    height: 24px;
    background-size: 100% 100%;
    &-imgok {
      background-image: url("../../assets/icon/qs-ov.png");
    }
    &-imgrefuse {
      background-image: url("../../assets/icon/qs-rd.png");
    }
    &-imgnow {
      background-image: url("../../assets/icon/qs-now.png");
    }
    &-imgnotSigned {
      background-image: url("../../assets/icon/qs-weiqianshu.png");
    }
  }
  .options-active-icon {
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
    &-qianshu {
      background-image: url("../../assets/icon/op-qianshu.png");
    }
    &-ju {
      background-image: url("../../assets/icon/op-jujue.png");
    }
    &-faq {
      background-image: url("../../assets/icon/op-faqi.png");
    }
    &-c {
      background-image: url("../../assets/icon/op-chehui.png");
    }
    &-look {
      background-image: url("../../assets/icon/op-look.png");
    }
    &-down {
      background-image: url("../../assets/icon/op-down.png");
    }
    &-dy {
      background-image: url("../../assets/icon/op-dayin.png");
    }
  }
  &-triangle {
    width: 0;
    height: 0;
    border-top: 8px solid #00D1B3;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    transform-origin: center;
    transform: rotate(-45deg);
    margin-top: 4px;
    margin-left: 10px;
}
}
.reson-box {
  width: 100%;
  // height: 260px;
  padding: 20px;
  box-sizing: border-box;
  &-title {
    height: 23px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 23px;
    color: #30586C;
    opacity: 1;
    margin-bottom: 16px;
  }
  &-textarea {
    width: 100%;
    height: 138px;
    border: 0;
    background: #F6F7F8;

    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    opacity: 1;

    padding: 12px;
    box-sizing: border-box;
    &::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #9c9c9c;

      }
  }
  &-btn {
    width: 100%;
    height: 44px;
    background: #0067E1;

    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 44px;
    color: #FFFFFF;
    opacity: 1;
    border-radius: 30px;
    text-align: center;
    margin-top: 20px;
  }
}
</style>